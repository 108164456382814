@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@font-face {
	font-family: Avant-Garde;
	src: url(/static/media/itc-avant-garde-gothic-std-book-58957161d80eb.d7ed911a.otf);
}
@font-face {
	font-family: Avant-Garde-Bold;
	src: url(/static/media/itc-avant-garde-gothic-std-bold-589572c7e9955.e72722fb.otf);
}

body {
  margin: 0;
  font-family: Avant-Garde, 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*
*
*
* REACT-TABLE-STYLES
*
*/

.ReactTable {
	border-radius: 5px;
  overflow: hidden;
  color: #FFFFFF;
}

.ReactTable:hover {
	border: 1px solid #62CAE4;
}

.ReactTable .rt-tbody {
  overflow: hidden;
}
.ReactTable .rt-thead.-header,
.ReactTable .rt-thead.-headerGroups {
	background: #62CAE4; /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #62CAE4 0%, #437FC1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.ReactTable .rt-thead.-header .rt-th,
.ReactTable .rt-thead.-headerGroups .rt-th {
	color: #FFFFFF;
	padding: 10px 5px;
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	border-right: 1px solid rgba(255, 255, 255, 0.25);
}
@media (max-width: 1000px) {
	.ReactTable .rt-thead.-header .rt-th,
	.ReactTable .rt-thead.-headerGroups .rt-th {
		font-size: 13px;
		padding: 8px 5px;
	}
}
@media (max-width: 800px) {
	.ReactTable .rt-thead.-header .rt-th,
	.ReactTable .rt-thead.-headerGroups .rt-th {
		font-size: 12px;
	}
}
/* @media (max-width: 650px) {
	.ReactTable .rt-thead.-header .rt-th,
	.ReactTable .rt-thead.-headerGroups .rt-th {
		padding: 5px 5px;
	}
} */

.ReactTable .rt-thead.-filter .rt-th {
	color: #FFFFFF;
	padding: 10px 5px;
}
@media (max-width: 800px) {
	.ReactTable .rt-thead.-filter .rt-th {
		padding: 8px 5px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-thead.-filter .rt-th {
		padding: 5px;
	}
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
	box-shadow: inset 0 3px 0 0 #FFFFFF;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
	box-shadow: inset 0 -3px 0 0 #FFFFFF;
}

.ReactTable .rt-thead .rt-th input[type='text'] {
  background-image: url(/static/media/searchIcon.78544753.png);
  background-color: #FFFFFF10;
	background-position: calc(100% - 3px) center;
	background-repeat: no-repeat;
	background-size: auto calc(100% - 6px);
	padding: 5px;
	border: 1px solid #00000000;
	border-radius: 5px;
	font-size: 16px;
  color: #FFFFFF;
	-webkit-transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
	transition: border-color 0.2s ease-in, box-shadow 0.2s ease-in;
}

@media (max-width: 1000px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 14px;
	}
}
@media (max-width: 800px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 14px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-thead .rt-th input[type='text'] {
		font-size: 12px;
	}
}

.ReactTable .rt-thead .rt-th input[type='text']:hover {
	border-color: #62CAE4;
	box-shadow: 0 0 0 1px #000000 inset;
}

.ReactTable .rt-tbody .rt-td {
	align-self: center;
	padding: 10px 5px;
	font-size: 14px;
	min-height: 30px;
}

.ReactTable .rt-tbody .-even .rt-td {
  background-color: #FFFFFF08;
}


@media (max-width: 800px) {
	.ReactTable .rt-tbody .rt-td {
		padding: 8px 5px;
		font-size: 13px;
	}
}
@media (max-width: 650px) {
	.ReactTable .rt-tbody .rt-td {
		padding: 5px;
		font-size: 12px;
	}
}

.ReactTable .rt-thead .rt-th.right {
	text-align: right;
}

.ReactTable .rt-tbody .rt-td.actionCell {
	padding: 5px;
	white-space: normal;
	display: flex;
	flex-wrap: wrap;
}
.ReactTable .rt-tbody .rt-td.noPadding {
	padding: 0px;
}
.ReactTable .rt-tbody .rt-td.center {
	text-align: center;
}
.ReactTable .rt-tbody .rt-td.capitalize {
	text-transform: capitalize;
}
.ReactTable .rt-tbody .rt-td.center-capitalize {
	text-align: center;
	text-transform: capitalize;
}
.ReactTable .rt-tbody .rt-td.right {
	text-align: right;
}
.ReactTable .rt-tbody .rt-td.wrap {
	white-space: pre-line;
}

.ReactTable .pagination-bottom .-pagination .-btn {
	background-color: #62CAE4;
}

.ReactTable .pagination-bottom .-pagination .-btn:hover {
	background-color: #62CAE4;
}

/*
*
* PowerSelect
*
*/

.PowerSelect {
		color: #FFFFFF;
		background-color: #323233;
}

.PowerSelect__Menu {
		color: #FFFFFF;
		background-color: #323233;
		border: none !important;
}

.PowerSelect__Clear::before {
	color: #62CAE4;
}

.PowerSelect__SearchInput {
	background-color: #606060;
	color: #FFFFFF;
}

.PowerSelect__Menu div {
	background-color: #323233;
}

.PowerSelect__Menu div:hover {
	background-color: #484848;
}

/*
*
* DatePicker
*
*/

input.react-datepicker-time__input {
	background-color: #FFFFFF !important;
	border: 1px solid lightgray !important;
}

div.react-datepicker-popper {
	z-index: 5;
}
.TEPanelTitleTitle {
}
.TESubNavbarUl .TESubNavbarLi {
	width: auto;
}
.TESubNavbarLink {
	padding: 0px 15px;
}

.TESideNavLinkLink.active,
.TESideNavLinkLink:active,
.TESideNavLinkLink:hover {
	background-color: #dbdae5;
	-webkit-transition: color 0.2s ease-in, border 0.2s ease-in, background-color 0.2s ease-in;
	transition: color 0.2s ease-in, border 0.2s ease-in, background-color 0.2s ease-in;
}

html, body, #root {
	height: 100%;
}
