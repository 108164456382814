.TEPanelTitleTitle {
}
.TESubNavbarUl .TESubNavbarLi {
	width: auto;
}
.TESubNavbarLink {
	padding: 0px 15px;
}

.TESideNavLinkLink.active,
.TESideNavLinkLink:active,
.TESideNavLinkLink:hover {
	background-color: #dbdae5;
	transition: color 0.2s ease-in, border 0.2s ease-in, background-color 0.2s ease-in;
}

html, body, #root {
	height: 100%;
}